import { useEffect, useState } from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { useDarkMode } from "./DarkModeContect";
import Navbar from "./Navbar";

function Modal({ show, handleClose, children }) {
    return (
      <div className={`modal ${show ? "show" : ""}`}>
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {children}
        </div>
      </div>
    );
  }

export default function HeroSection() {

    const { darkMode } = useDarkMode();

    const [text] = useTypewriter({
        words: ['Web Developer 💻','UI/UX Designer 📝','Prod Operation ⚙️','Content Creator 📹','Photography 📷'],
        loop: {},
        typeSpeed: 60,
        deleteSpeed: 40
    });

    const [name] = useTypewriter({
        words: ['Handi'],
        loop: {},
        typeSpeed: 120,
        deleteSpeed: 50
    });

    const [aboutme] = useTypewriter({
        words: ['A highly motivated software engineer - UI/UX Designer with more than 4 year(s) of experience in developing, testing and deploying and also in designing. Experience in ASP, PHP, HTML, JS, CSS, Android, ReactJS & Native, SQL. Adept at collaborating with cross-functional teams to deliver high-quality software within tight deadlines'],
        loop: 1,
        typeSpeed: 120
    });

    const [showModal, setShowModal] = useState(false);
    

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const highlightedName = () => {
        const fullName = name;
        const firstName = fullName.split(' ')[0];
        const lastName = fullName.split(' ').slice(1).join(' ');
        return(
            <span>
                <span className="section--title--name">{firstName}</span> {lastName}
            </span>
        )
    }

    return(
        
            <section id="heroSection" className={`hero--section ${darkMode ? "dark": ""}`}>
                <div className="hero--section--content--box">
                    <div className="hero--section--content">
                        <p data-aos="fade-right" className="section--title">
                            Hey, I'm&nbsp; 
                            {/* {highlightedName()} */}
                            <span className="section--title--name">
                                {name}
                            </span>
                            <span style={{color: "red"}}>
                                <Cursor cursorStyle='|' />
                            </span>
                        </p>
                            {/* &nbsp;<h5>&nbsp;I am a</h5> */}
                        <h1 data-aos="fade-left" className="hero--section--title">
                            <span className="hero--section-title--color">
                                {text}
                            </span>
                            <span style={{color: "red"}}>
                                <Cursor cursorStyle='|' />
                            </span>
                            <br />
                        </h1>
                        <p className="section-title">
                            
                            {/* {aboutme} <br /> */}
                            {/* {currentText} */}
                            {/* <span className="cursor" style={{opacity: currentText.length === texts[index].length ? 0 : 1}}>|</span> */}

                            {/* Lines of code in a row, tirelessly,
                            <br /> Dancing on The Keyboard, Creating Masterpieces. */}
                        </p>

                        <div data-aos="fade-right" className="container-section-getInTouch">
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <a href="https://www.linkedin.com/in/handi-putra-t-8a5ba1156">
                                    <button className="btn btn-primary contact-btn">
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                                        alt="LinkedIn"
                                        className="linkedin-icon"
                                    />
                                        Get In Touch
                                    </button>
                                </a>

                                {/* &nbsp;&nbsp;&nbsp;

                                <button className="btn btn-white btn-white-about-me" onClick={handleOpenModal}>
                                    About Me
                                </button> */}

                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="hero--section--img">
                    <img data-aos="zoom-in" src={darkMode ? "./img/handi_dark.png" : "./img/handi_light.png"} alt="Hero Section" />
                </div>

                <Modal show={showModal} handleClose={handleCloseModal}>
                    <h2>About Me</h2>
                    <p>Hello! I'm Handi, a passionate Web Developer, UI/UX Designer, Content Creator, and Photographer. I love creating amazing web experiences and capturing beautiful moments. Follow me on my journey to create and inspire!</p>
                </Modal>
            </section>
    )
}