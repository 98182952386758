import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from "./Pages/Home/Navbar";
import Home from "./Pages/Home/Homescreen";
import { DarkModeProvider } from './Pages/Home/DarkModeContect';

function App() {
  return (
    <div className="App">
      <Router>
        <div>
        <DarkModeProvider>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='*' element={<div>404 Not Found</div>}></Route>
          </Routes>
        </DarkModeProvider>
        </div>
      </Router>
    </div>
  );
}

export default App;
