import { useEffect, useState } from "react";
import data from "../../data/index.json";
import { useDarkMode } from "./DarkModeContect";

export default function MySkills() {
  const { darkMode } = useDarkMode();

  return (

      <section className={`skills--section ${darkMode ? "dark" : ""}`} id="MySkills">
        <div className="portfolio--container">
          <p data-aos="fade-right" className="section--title">My Skills</p>
          <h2 data-aos="fade-left" className="skills--section--heading">My Expertise</h2>
        </div>
        <div data-aos="fade-right" data-aos-delay="500" className="skills--section--container">
          {data?.skills?.map((item, index) => (
            <div key={index} className="skills--section--card">
              <div className="skills--section--img">
                <img src={item.src} alt="Product Chain" />
              </div>
              <div className="skills--section--card--content">
                <h3 className="skills--section--title">{item.title}</h3>
                <p className="skills--section--description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    
  );
}