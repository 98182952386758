import { useState } from "react";
import { useDarkMode } from "./DarkModeContect";

export default function ContactMe() {
    const { darkMode } = useDarkMode();

    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      topic: '',
      message: '',
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const { firstName, lastName, email, phoneNumber, topic, message } = formData;
      const mailtoLink = `mailto:handi.p.tjioe@banksinarmas.com?subject=${encodeURIComponent(topic)}&body=${encodeURIComponent(
        `First Name: ${firstName}\nLast Name: ${lastName}\nEmail: ${email}\nPhone Number: +${phoneNumber}\n\nMessage:\n${message}`
      )}`;
      window.location.href = mailtoLink;
    };
    
    return (
      <section id="Contact" className={`contact--section ${darkMode ? "dark" : ""}`}>
        <div>
          <p data-aos="fade-right" className="sub--title">Get In Touch</p>
          <h2 data-aos="fade-left">Contact Me</h2>
          <p data-aos="fade-right" className="text-lg">
            Wants to Collaboration / Give me a Feedback / Any Other Questions
          </p>
        </div>
        <form className="contact--form--container" onSubmit={handleSubmit}>
          <div className="container">
            <label data-aos="fade-right" htmlFor="first-name" className="contact--label">
              <span className="text-md">First Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="firstName"
                id="first-name"
                placeholder="ex: Sam"
                required
                onChange={handleChange}
              />
            </label>
            <label data-aos="fade-right" htmlFor="last-name" className="contact--label">
              <span className="text-md">Last Name</span>
              <input
                type="text"
                className="contact--input text-md"
                name="lastName"
                id="last-name"
                placeholder="ex: Smith"
                required
                onChange={handleChange}
              />
            </label>
            <label data-aos="fade-left" htmlFor="email" className="contact--label">
              <span className="text-md">Email</span>
              <input
                type="email"
                className="contact--input text-md"
                name="email"
                id="email"
                placeholder="ex: abc123@gmail.com"
                required
                onChange={handleChange}
              />
            </label>
            <label data-aos="fade-left" htmlFor="phone-number" className="contact--label">
              <span className="text-md">phone-number</span>
              <input
                type="number"
                className="contact--input text-md"
                name="phoneNumber"
                id="phone-number"
                placeholder="ex: +628123456789"
                required
                onChange={handleChange}
              />
            </label>
          </div>
          <label data-aos="fade-right" htmlFor="choode-topic" className="contact--label">
            <span className="text-md">Choose a topic</span>
            <select id="choose-topic" name="topic" className="contact--input text-md"
              onChange={handleChange}
            >
              <option>Select One...</option>
              <option>Working Experience</option>
              <option>Collaboration</option>
              <option>Feedback</option>
              <option>CV/Resume</option>
            </select>
          </label>
          <label data-aos="fade-left" htmlFor="message" className="contact--label">
            <span className="text-md">Message</span>
            <textarea
              className="contact--input text-md"
              id="message"
              name="message"
              rows="8"
              placeholder="Type your message..."
              onChange={handleChange}
            />
          </label>

          <label data-aos="fade-right" htmlFor="checkboc" className="checkbox--label">
            <input type="checkbox" required name="checkbox" id="checkbox" checked />
            <span className="text-sm">I accept the terms</span>
          </label>
          
          <div>
            <button data-aos="fade-left" className="btn btn-primary contact--form--btn">Submit</button>
          </div>
        </form>
      </section>
    );
  }