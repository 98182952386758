import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useDarkMode } from './DarkModeContect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import lightLogo from '../HP-lightmode.svg';
import darkLogo from '../HP-darkmode2.svg';

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const { darkMode, toggleDarkMode } = useDarkMode();

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        closeMenu;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      closeMenu;
    }
  }, []);

  const logo = darkMode ? darkLogo : lightLogo;

  return (
    <nav className={`navbar ${navActive ? "active" : ""} ${darkMode ? "dark" : ""}`}>

      <Link
        to="heroSection"
        className="navbar--logo"
        onClick={closeMenu}
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        style={{ cursor: "pointer" }}
      >
        {/* <img src="./img/hp2.svg" alt="&lt;/&gt; Handi" /> */}
        <img src={logo} alt="&lt;/&gt; Handi" />
      </Link>
      
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="heroSection"
              className="navbar--content"
            >
              Home
            </Link>
          </li>

          {/* <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="AboutMe"
              className="navbar--content"
            >
              About Me
            </Link>
          </li> */}

          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="MySkills"
              className="navbar--content"
            >
              Skills
            </Link>
          </li>

          <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="MyPortfolio"
              className="navbar--content"
            >
              Showcase
            </Link>
          </li>

          {/* <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="Others"
              className="navbar--content"
            >
              Others
            </Link>
          </li> */}
          
          {/* <li>
            <Link
              onClick={closeMenu}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="testimonial"
              className="navbar--content"
            >
              Testimonials
            </Link>
          </li> */}
        </ul>
      </div>


      <div className="navbar--actions">

        {/* <button className="btn btn-dark-mode" onClick={toggleDarkMode}>
          {darkMode ? "Dark Mode" : "Light Mode"}
        </button> */}

        


        <Link
          onClick={closeMenu}
          activeClass="navbar--active-content"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          to="Contact"
          className="btn btn-outline-primary"
          >
          Contact Me
        </Link>
        
          &nbsp;&nbsp;&nbsp;&nbsp;

        <label className="switch">
          <input type="checkbox" checked={darkMode} onChange={toggleDarkMode} />
          <span className={`slider round ${darkMode ? 'dark-mode-round' : 'light-mode-round'}`}>
            {darkMode ? (
              <FontAwesomeIcon icon={faMoon} className="dark-mode-icon" />
            ) : (
              <FontAwesomeIcon icon={faSun} className="light-mode-icon" />
            )}
          </span>
        </label>

      </div>

    </nav>
  );
}

export default Navbar;