import React, { useState, useEffect } from 'react';
import data from "../../data/index.json";
import '../../../src/App.css';
import { useDarkMode } from './DarkModeContect';

export default function MyPortfolio() {
  const [activeTab, setActiveTab] = useState('portfolio');
  const [showMore, setShowMore] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    setDataLength(data[activeTab].length);
    setShowMore(false);
  }, [activeTab, data]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderIcon = () => {
    switch (activeTab) {
      case 'portfolio':
        return (
          
            <svg height="32" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="32" data-view-component="true" className="octicon octicon-mark-github v-align-middle">
              <path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8a8 8 0 005.47 7.59c.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.97 0-.88.31-1.6.82-2.16-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82a7.64 7.64 0 012-.27c.68 0 1.36.09 2 .27 1.53-1.03 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.28.82 2.16 0 3.09-1.87 3.77-3.65 3.97.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
            </svg>
          
        );
      case 'design':
        return (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 512 512"
              fill="#000000"
          >
              <g>
                  <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M381.3,86.8c35.3,32.6,61.2,75.6,72.2,124.5c-6.9-1.4-77.2-15.1-149.8-7.7c-5.7-13.4-11.3-25.7-18.2-38.7C320.9,139.4,356.7,110.1,381.3,86.8z M256,32c39.3,0,76.1,9.6,108.9,26.6c-22.5,22-56.4,50.7-94.1,72.6c-29.4-53.5-61.6-99.9-78.2-123.6C217.5,33.3,236.7,32,256,32z M176.2,41.9c16.5,23.2,47.3,67.2,76.1,120.5c-86.2,33.5-170.7,43.5-205.5,45.2C62.7,139.1,113.5,82.1,176.2,41.9z M32,256c0-0.7,0-1.4,0-2.1c37.6-1.2,115.2-7.3,205.9-42.8c5.6,10.8,10.9,21.6,16,32.5c-3.1,0.9-6.2,1.8-9.3,2.8c-82.4,26.3-134.8,80.6-156.5,108.4C54.5,323.6,32,291.5,32,256z M256,480c-57.5,0-109.9-21.1-150.5-55.9c20.4-28.2,68.1-87.4,152.9-116.4c3.3-1.1,6.5-2.1,9.8-3.1c34.6,91.1,49.7,167.3,54.5,195.6C304.6,478.5,280.6,480,256,480z M288.2,461.4c-4.3-24.8-18.7-96.6-51.8-183.8c4.4-1,8.9-1.9,13.4-2.9c70.8-13.8,136.4-7.4,175.9-1.4C408.5,356.3,354.7,423.6,288.2,461.4z M428.2,377.4c-38.2-7.3-97.4-15.2-164.7-3.7c-6.4-17.6-13.2-35.4-20.6-53.1c10.4-2.3,21-4.4,31.7-6.3c78.4-15.2,156.4-2.9,182.9,2.4C449.6,344.6,441.5,361.9,428.2,377.4z" />
              </g>
          </svg>
        );

        case 'article':
          return (
            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.986 6.266C3.007 6.032 2.92 5.799 2.75 5.634L1.196 3.755V3.5H6.263L10.3 13.8L13.888 3.5H18.673V3.755L17.312 5.012C17.191 5.105 17.135 5.264 17.17 5.418V18.582C17.135 18.736 17.191 18.895 17.312 18.988L18.625 20.245V20.5H11.27V20.245L12.632 18.95C12.764 18.856 12.764 18.821 12.764 18.641V7.522L8.91 20.461H8.443L4.004 7.522V16.04C3.959 16.346 4.054 16.655 4.261 16.871L6.35 20.194V20.448H1.327V20.194L3.416 16.871C3.615 16.655 3.704 16.342 3.658 16.04V6.266H2.986Z" fill="black"/>
            </svg>

          );

      case 'contentcreator':
        return (
          <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8,8.001c-0.276-1.037-1.081-1.848-2.102-2.122C18.274,5.5,12,5.5,12,5.5s-6.274,0-7.698,0.379 c-1.02,0.274-1.826,1.085-2.102,2.122C2,9.5,2,12,2,12s0,2.5,0.2,3.999c0.276,1.037,1.081,1.848,2.102,2.122 C5.726,18.5,12,18.5,12,18.5s6.274,0,7.698-0.379c1.02-0.274,1.826-1.085,2.102-2.122C22,14.5,22,12,22,12S22,9.5,21.8,8.001z M10,15.5V8.5l6,3.5L10,15.5z" />
          </svg>
        );
      case 'photography':
        return (
          <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
            <path d="M12,7c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S14.761,7,12,7z M12,15c-1.657,0-3-1.343-3-3s1.343-3,3-3 s3,1.343,3,3S13.657,15,12,15z M20,5h-2.586l-2-2H8.586l-2,2H4C2.897,5,2,5.897,2,7v10c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V7 C22,5.897,21.103,5,20,5z M12,17c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S14.757,17,12,17z" />
          </svg>
        );
      default:
        return null;
    }
  };

  const renderIconWeb = () => {
    switch (activeTab) {
      case 'portfolio':
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
              <circle cx="12" cy="12" r="10" fill="none" stroke="black" stroke-width="2"/>
              <line x1="12" y1="2" x2="12" y2="22" stroke="black" stroke-width="2"/>
              <line x1="2" y1="12" x2="22" y2="12" stroke="black" stroke-width="2"/>
              <line x1="6.97" y1="6.97" x2="17.03" y2="17.03" stroke="black" stroke-width="2"/>
              <line x1="6.97" y1="17.03" x2="17.03" y2="6.97" stroke="black" stroke-width="2"/>
            </svg>


          </>
        );
      // case 'design':
      //   return (
      //     <svg
      //         xmlns="http://www.w3.org/2000/svg"
      //         width="32"
      //         height="32"
      //         viewBox="0 0 512 512"
      //         fill="#000000"
      //     >
      //         <g>
      //             <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M381.3,86.8c35.3,32.6,61.2,75.6,72.2,124.5c-6.9-1.4-77.2-15.1-149.8-7.7c-5.7-13.4-11.3-25.7-18.2-38.7C320.9,139.4,356.7,110.1,381.3,86.8z M256,32c39.3,0,76.1,9.6,108.9,26.6c-22.5,22-56.4,50.7-94.1,72.6c-29.4-53.5-61.6-99.9-78.2-123.6C217.5,33.3,236.7,32,256,32z M176.2,41.9c16.5,23.2,47.3,67.2,76.1,120.5c-86.2,33.5-170.7,43.5-205.5,45.2C62.7,139.1,113.5,82.1,176.2,41.9z M32,256c0-0.7,0-1.4,0-2.1c37.6-1.2,115.2-7.3,205.9-42.8c5.6,10.8,10.9,21.6,16,32.5c-3.1,0.9-6.2,1.8-9.3,2.8c-82.4,26.3-134.8,80.6-156.5,108.4C54.5,323.6,32,291.5,32,256z M256,480c-57.5,0-109.9-21.1-150.5-55.9c20.4-28.2,68.1-87.4,152.9-116.4c3.3-1.1,6.5-2.1,9.8-3.1c34.6,91.1,49.7,167.3,54.5,195.6C304.6,478.5,280.6,480,256,480z M288.2,461.4c-4.3-24.8-18.7-96.6-51.8-183.8c4.4-1,8.9-1.9,13.4-2.9c70.8-13.8,136.4-7.4,175.9-1.4C408.5,356.3,354.7,423.6,288.2,461.4z M428.2,377.4c-38.2-7.3-97.4-15.2-164.7-3.7c-6.4-17.6-13.2-35.4-20.6-53.1c10.4-2.3,21-4.4,31.7-6.3c78.4-15.2,156.4-2.9,182.9,2.4C449.6,344.6,441.5,361.9,428.2,377.4z" />
      //         </g>
      //     </svg>
      //   );
      // case 'contentcreator':
      //   return (
      //     <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
      //       <path d="M21.8,8.001c-0.276-1.037-1.081-1.848-2.102-2.122C18.274,5.5,12,5.5,12,5.5s-6.274,0-7.698,0.379 c-1.02,0.274-1.826,1.085-2.102,2.122C2,9.5,2,12,2,12s0,2.5,0.2,3.999c0.276,1.037,1.081,1.848,2.102,2.122 C5.726,18.5,12,18.5,12,18.5s6.274,0,7.698-0.379c1.02-0.274,1.826-1.085,2.102-2.122C22,14.5,22,12,22,12S22,9.5,21.8,8.001z M10,15.5V8.5l6,3.5L10,15.5z" />
      //     </svg>
      //   );
      // case 'photography':
      //   return (
      //     <svg height="32" viewBox="0 0 24 24" width="32" xmlns="http://www.w3.org/2000/svg">
      //       <path d="M12,7c-2.761,0-5,2.239-5,5s2.239,5,5,5s5-2.239,5-5S14.761,7,12,7z M12,15c-1.657,0-3-1.343-3-3s1.343-3,3-3 s3,1.343,3,3S13.657,15,12,15z M20,5h-2.586l-2-2H8.586l-2,2H4C2.897,5,2,5.897,2,7v10c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2V7 C22,5.897,21.103,5,20,5z M12,17c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S14.757,17,12,17z" />
      //     </svg>
      //   );
      default:
        return null;
    }
  };

  const renderContent = () => {
    const currentData = data[activeTab];
    return (
      <div data-aos="fade-right" data-aos-delay="250" className="portfolio--grid-container">
        {currentData.slice(0, showMore ? currentData.length : 3).map((item, index) => (
          // <a className="item--link" href={item.link} target="_blank">
          <div key={index} className="portfolio--section--card">
            <div className="portfolio--section--img">
              <img src={item.src} alt="Placeholder" />
            </div>
            <div className="portfolio--section--card--content">
              <div>
                <h3 className="portfolio--section--title">{item.title}</h3><br />
                <p className="text-porto-desc">
                  {/* {item.description} */}
                  {item.description.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              </div>
              <div className="link-container">
                <p className="text-sm portfolio--link">
                  <a className="item--link"  href={item.link} target="_blank">
                    {renderIcon()}
                  </a>
                  <a href={item.link1} className="item--link" target="_blank">
                    {renderIconWeb()}
                  </a>
                </p>                
              </div>
            </div>
          </div>
          // </a> 
        ))}
      </div>
    );
  };

  return (
    <section className={`portfolio--section ${darkMode ? "dark" : ""}`} id="MyPortfolio">
      <div className="portfolio--container-box">
        <div className="portfolio--container">
          <p data-aos="fade-left" className="sub--title">Recent Folio</p>
          <h2 data-aos="fade-right" className="section--heading">Showcase</h2>
        </div>
      </div>
      <div className="tabs-container">
        <div data-aos="fade-right" data-aos-delay="500" className="tabs">
          <button className={`tab ${activeTab === 'portfolio' ? 'active' : ''}`} onClick={() => setActiveTab('portfolio')}>Portfolio</button>
          <button data-aos-delay="250" className={`tab ${activeTab === 'design' ? 'active' : ''}`} onClick={() => setActiveTab('design')}>Design</button>
          <button data-aos-delay="500" className={`tab ${activeTab === 'article' ? 'active' : ''}`} onClick={() => setActiveTab('article')}>Article</button>
          <button data-aos-delay="750" className={`tab ${activeTab === 'contentcreator' ? 'active' : ''}`} onClick={() => setActiveTab('contentcreator')}>Content Creator</button>
          <button data-aos-delay="1000" className={`tab ${activeTab === 'photography' ? 'active' : ''}`} onClick={() => setActiveTab('photography')}>Photography</button>
        </div>
      </div>
      {renderContent()}
      {dataLength > 3 && (
        <div data-aos="fade-left" className='button--container'>
          <button className='btn btn-primary' onClick={handleShowMore}>
            {showMore ? "See Less" : "See More"}
          </button>
        </div>
      )}
    </section>
  );
}
