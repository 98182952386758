import AboutMe from "../AboutMe";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import MySkills from "../MySkills";
import Others from "../Others";
import Testimonial from "../Testimonials";
import { DarkModeProvider } from "../DarkModeContect";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from "react";
import Aos from "aos";

export default function Home() {
  // Animation Setup
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease",
      once: true,
      anchorPlacement: "top-bottom"
    })
  },[]);

  return (
    <>
        <HeroSection />
        {/* <AboutMe /> */}
        <MySkills />
        <MyPortfolio />
        {/* <Others /> */}
        {/* <Testimonial /> */}
        <ContactMe />
        <Footer />
    </>
  );
}